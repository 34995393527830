<template>
    <nuxt-link :id="blok._uid"
        :to="url"
        class="relative col-span-full row-start-auto flex h-[340px] w-full
            grid-cols-3 gap-x-xl rounded border-0 md:grid md:h-[650px]"
        :class="{
            'h-[450px] bg-center md:h-[650px]': inWideSlider,
        }"
        @click="addPromotionClick(promo)">
        <nuxt-picture v-if="blok.image?.filename"
            :src="blok.image.filename"
            :alt="blok.image.alt ?? blok.title"
            provider="storyblok"
            loading="lazy"
            class="absolute inset-0"
            sizes="xs:100vw sm:100vw md:768px lg:1024px xl:1280px 2xl:1536px"
            :img-attrs="{
                class: 'object-cover w-full h-full',
            }"
        />

        <span role="presentation"
            class="absolute inset-0 rounded bg-black/20"></span>

        <div class="z-[25] col-span-full m-auto grid gap-y-md px-10 md:gap-y-2xl">
            <p class="text-center font-prenton-cond text-2xl
                font-bold uppercase text-white md:text-6xl lg:text-12xl">
                {{ blok.title }}
            </p>

            <div v-if="blok.button_text"
                class="mx-auto rounded bg-[color:var(--background-color)] p-xs font-prenton-cond
                uppercase tracking-5xl text-[color:var(--text-color)]
                md:p-sm"
                :style="{
                    '--background-color': backgroundColor,
                    '--text-color': fontColor
                }">
                <span class="text-xl uppercase tracking-5xl md:leading-5">{{ blok.button_text }}</span>
            </div>
        </div>
    </nuxt-link>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-cta' | 'sb-cta-big'
        | 'sb-cta-button' | 'sb-cta-content' | 'sb-cta-image' | 'sb-cta-narrow' | 'sb-cta-countdown' }>
}>();

const inWideSlider = inject('sb.inWideSlider', false);
const { format: formatSbUrl } = useSbUrl();

const url = computed(() => formatSbUrl(properties.blok.button?.cached_url));

const fontColor = computed<string | undefined>(() => (
    properties.blok.button_text_color?.color ?? '#000'
));

const backgroundColor = computed<string | undefined>(() => (
    properties.blok.button_background_color?.color ?? '#FFF'
));

const route = useRoute();
const { addPromotionClick } = usePromotion();

function convertString(string: string) {
    const words = string.split('-');
    const convertedString = words.map(((word) => word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
    return convertedString;
}

const promo = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    creative_name: convertString(properties.blok.component),
    creative_slot: `${properties.blok.component} / 0`, // component name / index
    // eslint-disable-next-line no-underscore-dangle
    promotion_id: properties.blok._uid,
    promotion_name: properties.blok.name,
    source_url: route.fullPath,
    target_url: url.value,
    /* eslint-enable @typescript-eslint/naming-convention */
}));
</script>
